<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">New data</div>
            <div class="save flex items-center justify-center ml-auto" @click.prevent="save">
                <feather-icon icon="CheckIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-select class="w-full" v-model="form.tour_id" :disabled="options.length === 0" label="Tour">
                        <vs-select-item :key="index" :value="item.id" :text="item.title"
                            v-for="(item, index) in options" />
                    </vs-select>
                    <div class="error-msg" v-if="validation.hasError('form.tour_id')">
                        {{validation.firstError('form.tour_id')}}
                    </div>
                </div>
                <div class="vx-col w-full mt-2 flex">
                    <a href="javascript:;" @click="$router.push('/add-tours1')" class="generate-info ml-auto">Add Tours
                    </a>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Price" v-model="form.price"
                        :danger="validation.hasError('form.price')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.price')" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Title" v-model="form.title"
                        :danger="validation.hasError('form.title')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.title')" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label">
                        Description
                    </label>
                    <quill-editor v-model="form.description" :options="editorOption"></quill-editor>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label mb-3">
                        Thumbnail
                    </label>
                    <picture-input :z-index="90" ref="thumbnail" width="300" height="120" margin="16" accept="image/*"
                        size="2" :alertOnError="false" @error="errorThumbnail"
                        removeButtonClass="vs-component vs-button vs-button-primary vs-button-gradient small w-1/4"
                        button-class="vs-component vs-button w-1/4 vs-button-warning vs-button-border small"
                        :removable="false" :custom-strings="{
                                    upload: '<h1>Bummer!</h1>',
                                    drag: 'Upload'
                                }">
                    </picture-input>
                </div>
            </div>
            <div class="vx-row mb-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Save</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Back</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import SimpleVueValidation from 'simple-vue-validator'
    import PictureInput from 'vue-picture-input'
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";

    import {
        quillEditor
    } from "vue-quill-editor";
    const Validator = SimpleVueValidation.Validator

    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                options: [],
                form: {
                    title: '',
                    description: '',
                    tour_id: '',
                    price: ''
                },
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ["bold", "italic", "underline", "strike"],
                                [{
                                    align: []
                                }],
                                [{
                                    header: [2, 3, 4, 5, 6, false]
                                }],
                                [{
                                        list: "ordered"
                                    },
                                    {
                                        list: "bullet"
                                    }
                                ],
                                ["blockquote", "code-block"],
                                [{
                                    color: []
                                }, {
                                    background: []
                                }],
                            ]
                        },
                        history: {
                            delay: 1000,
                            maxStack: 50,
                            userOnly: false
                        }
                    }
                }
            }
        },
        validators: {
            'form.title': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
            'form.tour_id': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
             'form.price': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).digit()
                }
            },
        },
        components: {
            PictureInput,
            "quill-editor": quillEditor,
        },
        mounted() {
            this.loadData()
        },
        methods: {
            errorThumbnail(error) {
                this.$vs.notify({
                    title: 'Oops!',
                    text: error.message,
                    color: 'danger',
                    position: 'top-center'
                })
            },
            back() {
                this.$router.go(-1)
            },
            async loadData() {
                this.$vs.loading()
                await this.getData().then(resp => {
                    this.options = resp
                })
                this.$vs.loading.close()
            },
            getData() {
                return new Promise((resolve, reject) => {
                    this.$http({
                            url: 'v1/id/tours',
                            method: 'GET',
                        })
                        .then(response => {
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
            },
            save() {
                this.$validate().then(result => {
                    if (result) {
                        this.actionSave().then(async () => {
                                this.$vs.notify({
                                    title: 'Success!',
                                    text: 'Saved!',
                                    color: 'success',
                                    position: 'top-center'
                                })
                                await this.clearForm();
                                this.validation.reset();
                            })
                            .catch(err => {
                                this.$vs.notify({
                                    title: 'Oops!',
                                    text: err.response ? err.response.data.message :
                                        'Something wrong, ' + err,
                                    color: 'danger',
                                    position: 'top-center'
                                })
                                this.$vs.loading.close()
                            })
                    }
                })
            },
            actionSave() {
                return new Promise((resolve, reject) => {
                    const fd = new FormData;
                    fd.append('title', this.form.title)
                    fd.append('description', this.form.description)
                    fd.append('image', this.$refs.thumbnail.file)
                    fd.append('tour_id', this.form.tour_id)
                    fd.append('price', this.form.price)
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/id/toursdetail',
                            method: 'POST',
                            data: fd
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            clearForm() {
                this.form.title = ""
                this.form.description = ""
                this.form.tour_id = ""
                this.$refs.thumbnail.removeImage()
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>